// const getPosition = ({ lastWidget, widget, fullWidth }) => {
//   if (lastWidget.x + lastWidget.w + widget.w <= fullWidth) {
//     return { x: lastWidget.x + lastWidget.w, y: lastWidget.y }
//   }
//   return { x: 0, y: lastWidget.y + lastWidget.h }
// }
const getPositionV2 = ({ widgets, widget, fullWidth }) => {
  const sortedWidgets = widgets.sort((a, b) => {
    if (a.y === b.y) {
      return a.x - b.x
    }
    return a.y - b.y
  })
  for (let i = 0; i < sortedWidgets.length; i += 1) {
    if (i < sortedWidgets.length - 1) {
      // between two widgets in the same line
      if (sortedWidgets[i].y === sortedWidgets[i + 1].y) {
        if (
          sortedWidgets[i].x + sortedWidgets[i].w + widget.w
          <= sortedWidgets[i + 1].x
        ) {
          return {
            x: sortedWidgets[i].x + sortedWidgets[i].w,
            y: sortedWidgets[i].y,
          }
        }
        // eslint-disable-next-line
      }
      // between two widgets in different line
      else if (
        sortedWidgets[i].x + sortedWidgets[i].w + widget.w
        <= fullWidth
      ) {
        return {
          x: sortedWidgets[i].x + sortedWidgets[i].w,
          y: sortedWidgets[i].y,
        }
      }
    } else {
      // add to the end
      if (sortedWidgets[i].x + sortedWidgets[i].w + widget.w <= fullWidth) {
        return {
          x: sortedWidgets[i].x + sortedWidgets[i].w,
          y: sortedWidgets[i].y,
        }
      }
      return {
        x: 0,
        y: widgets[i].y + widgets[i].h,
      }
    }
  }
  // add to new line
  return {
    x: 0,
    y: widgets[widgets.length - 1].y + widgets[widgets.length - 1].h,
  }
}

export default {
  namespaced: true,
  state: {
    boards: [],
    widgets: [],
  },
  getters: {
    widgets(state) {
      return state.widgets
    },
  },
  mutations: {
    MUTATE_WIDGET(state, { boardIndex, widgetIndex, value }) {
      state.boards[boardIndex].widgets[widgetIndex].data = value
    },
    RESIZE_WIDGET(state, { boardIndex, widgetIndex, newHeight }) {
      state.boards[boardIndex].widgets[widgetIndex].h = newHeight
    },
    ADD_BOARD(state, prevId) {
      if (state.boards.length) state.boards.push({ isActive: false, widgets: [], id: prevId || crypto.randomUUID() })
      else state.boards.push({ isActive: true, widgets: [], id: prevId || crypto.randomUUID() })
      sessionStorage.setItem('storedBoards', JSON.stringify(state.boards))
    },
    REMOVE_BOARD(state, index) {
      state.widgets.splice(index, 1)
      sessionStorage.setItem('storedWidgets', JSON.stringify(state.widgets))
      state.boards.splice(index, 1)
      sessionStorage.setItem('storedBoards', JSON.stringify(state.boards))
    },
    SELECT_BOARD(state, index) {
      state.boards.forEach(board => {
        board.isActive = false
      })
      state.boards[index].isActive = true
      sessionStorage.setItem('storedBoards', JSON.stringify(state.boards))
    },
    ADD_WIDGET(
      state,
      {
        boardIndex, type, width = 4, height = 10, data, fullWidth = 12, focus = true, boardId,
      },
    ) {
      const templateWidget = {
        x: 0,
        y: 0,
        w: width,
        h: height,
        i: 0,
        static: false,
        uniqueId: Date.now().toString() + Math.floor(Math.random() * 100),
        board: boardId,
        type,
        data,
        focus,
        turnOffFocus() {
          setTimeout(() => {
            this.focus = false
          }, 1000)
        },
      }
      const selectedBoard = state.boards.find(board => board.id === boardId)
      if (selectedBoard.widgets.length === 0) {
        selectedBoard.widgets.push(templateWidget)
        state.widgets.push({
          ...templateWidget,
          boardIndex,
          focus: false,
        })
        sessionStorage.setItem('storedWidgets', JSON.stringify(state.widgets))
        sessionStorage.setItem('storedBoards', JSON.stringify(state.boards))
      } else {
        const { x, y } = getPositionV2({
          widgets: selectedBoard.widgets,
          widget: { w: width, h: height },
          fullWidth,
        })
        const i = selectedBoard.widgets[
          selectedBoard.widgets.length - 1
        ].i + 1
        selectedBoard.widgets.push({
          ...templateWidget,
          boardIndex,
          x,
          y,
          i,
        })
        state.widgets.push({
          ...templateWidget,
          boardIndex,
          focus: false,
          x,
          y,
          i,
        })
        sessionStorage.setItem('storedBoards', JSON.stringify(state.boards))
        sessionStorage.setItem('storedWidgets', JSON.stringify(state.widgets))
      }
    },
    REMOVE_WIDGET(state, { boardIndex, widgetIndex }) {
      // eslint-disable-next-line
      const target = state.widgets.findIndex(widget => {
        const existingBoard = (typeof widget.boardIndex === 'undefined') || widget.boardIndex === boardIndex
        return existingBoard && (widget.i === widgetIndex)
      })
      state.boards[boardIndex].widgets.splice(widgetIndex, 1)
      state.widgets.splice(target, 1)
      sessionStorage.setItem('storedBoards', JSON.stringify(state.boards))
      sessionStorage.setItem('storedWidgets', JSON.stringify(state.widgets))
    },
  },
}
