// render file once fetching from API
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// Get user web client to adapt functionality like scrolling onto widgets
export function browser() {
  if (
    (navigator.userAgent.indexOf('Opera')
      || navigator.userAgent.indexOf('OPR')) !== -1
  ) {
    return 'Opera'
  }
  if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge'
  }
  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome'
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari'
  }
  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox'
  }
  return 'unknown'
}

// Get char based on its index
export function matchIndex(match, values) {
  values = Array.from(values)
  const matchedIndex = []
  let idx = values.indexOf(match)
  while (idx !== -1) {
    matchedIndex.push(idx)
    idx = values.indexOf(match, idx + 1)
  }
  return matchedIndex
}

// get numbers inside a text string
export function getNumbers(value) {
  const number = value.replace(/[^\d|.-]/g, '').replace(/(?!^)-/g, '').replace(/(\.){2,}/g, '$1')
  return number
}

// merge array
export function insertAt(array, index, ...elementsArray) {
  array.splice(index, 0, ...elementsArray)
}

// parse text to generate proper URL route
export function slugify(text) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\u0100-\uFFFF\w-]/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

// Function recives a string to valide if is the same e.g. USDUSD, EUREUR
export function isMirrorValue(value) {
  const isMirror = value.text.slice(0, 3) === value.text.slice(3)
  return isMirror
}

export function getModelValues(model) {
  return Object.entries(model).reduce((acc, [key, { value }]) => {
    acc[key] = value
    return acc
  }, {})
}

export function handleSwaggerValidationErrorMessages(errors, refs) {
  errors.forEach(error => {
    const errorCopy = { ...error }
    // Remove first element of error array beacause stepname is always root
    errorCopy.trace.shift()
    // Join stepnames with a slash at the end
    let errorId = ''
    errorCopy.trace.forEach(el => {
      errorId += `${el.stepName}/`
    })
    // Remove the las slash
    errorId = errorId.slice(0, -1)
    const ref = refs[`${errorId}-input`]
    const errorRef = refs[`${errorId}-error`]
    if (ref) {
      // if there is an error, add error classes and custom error depending the case
      ref.$el.classList.add('is-invalid')
      errorRef.classList.value = 'd-flex text-danger'
      errorRef.innerText = handleErrorMessages(error)
    }
  })
  function handleErrorMessages(error) {
    let errorMsg = 'error'
    // TODO: Move messages to I18n
    function constraintsViolation() {
      switch (error.constraintName) {
        case 'minLength':
          errorMsg = `Error: minimo ${error.constraintValue} caracteres`
          break

        case 'maxLength':
          errorMsg = `Error: máximo ${error.constraintValue} caracteres`
          break

        case 'maximum':
          errorMsg = `Error: el valor máximo es ${error.constraintValue}`
          break

        case 'minimum':
          errorMsg = `Error: el valor minimo es ${error.constraintValue}`
          break

        case 'pattern':
          errorMsg = 'Error: Asegurate de ingresar un valor correcto'
          break

        default:
          errorMsg = `Error: No se encontro la validacion para el tipo ${error.constraintName}`
          break
      }
    }

    function typeMismatch() {
      errorMsg = `Error: El valor es un ${error.typeIs} y tiene que ser un ${error.typeShouldBe}`
    }

    switch (error.errorType) {
      case 'CONSTRAINTS_VIOLATION':
        constraintsViolation()
        break
      case 'TYPE_MISMATCH':
        typeMismatch()
        break

      default:
        break
    }

    return errorMsg
  }
}

export const sortRemote = orderBy => {
  if (!orderBy) return ''
  const response = Object.entries(orderBy).map(([clave, valor]) => (`OrderBy[${clave}]=${valor}`)).join('&')
  return response
}

export const calculatePenalty = interval => {
  if (interval.spreadDivisor > 0 && interval.transactionTypeSpread > 0) {
    // Para calcular el castigo se usa: (TransactionTypeSpread / SpreadDivisor)\
    interval.intervalSpread = +interval.transactionTypeSpread / +interval.spreadDivisor
  }
}

export const sortTable = (sortBy, sortDesc, items) => {
  items.sort((a, b) => {
    const propA = a[sortBy]
    const propB = b[sortBy]
    const isNumber = typeof propA === 'number' && typeof propA === 'number'
    if (isNumber) {
      if (!sortDesc) {
        return propA - propB
      }
      return propB - propA
    }
    if (!sortDesc) {
      return String(propA).localeCompare(String(propB))
    }
    return String(propB).localeCompare(String(propA))
  })
}

export default {
  getBase64,
  browser,
  matchIndex,
  getNumbers,
  insertAt,
  slugify,
}
