import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPortfolios(
      _,
      {
        filterByIsActive, filterByType, filterByTypeAndEmployee, currentPage, perPage, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/portfolios?${sortRemote(orderBy)}`, {
            params: {
              FilterByIsActive: filterByIsActive,
              FilterByType: filterByType,
              FilterByTypeAndEmployee: filterByTypeAndEmployee,
              PerPage: perPage,
              CurrentPage: currentPage,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAuthorizedByClient(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/portfolios/get_authorized_by_client/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPortfoliosFX() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/portfolios/get_fx', {
            params: {
              IsAllIncluded: false,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getPortfolioById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/portfolios/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchAssociatedAccount(
      _,
      {
        filterByIsActive, filterByPortfolioId, perPage, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/associated_account?${sortRemote(orderBy)}`, {
            params: {
              FilterByIsActive: filterByIsActive,
              FilterByPortfolioId: filterByPortfolioId,
              PerPage: perPage,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTreasuryAccount(
      _,
      {
        filterByIsActive, fiterByActiveTreasuryAccountsByCurrencyId, filterByIsTreasuryAccountsToAssociate, portfolioId, perPage, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/treasury_account?${sortRemote(orderBy)}`, {
            params: {
              FilterByIsActive: filterByIsActive,
              FiterByActiveTreasuryAccountsByCurrencyId: fiterByActiveTreasuryAccountsByCurrencyId,
              FilterByIsTreasuryAccountsToAssociate: filterByIsTreasuryAccountsToAssociate,
              PortfolioId: portfolioId,
              PerPage: perPage,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveAssociatedAccount(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/associated_account', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    saveNewPortfolios(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/portfolios', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updatePortfolios(_, { id, data }) {
      const file = new FormData()
      file.append('PortfolioId', data.portfolioId)
      file.append('PortfolioCode', data.portfolioCode)
      file.append('PortfolioName', data.portfolioName)
      file.append('IsSelected', data.isSelected)
      file.append('PortfolioTypeCode', data.portfolioTypeCode)
      file.append('PortfolioCountry', data.portfolioCountry)
      file.append('PortfolioOpenPositionAmount', data.portfolioOpenPositionAmount)
      file.append('PortfolioMaxAmount', data.portfolioMaxAmount)
      file.append('PortfolioIsActive', data.portfolioIsActive)
      file.append('PortfolioIsFrameworkContactRequired', data.portfolioIsFrameworkContactRequired)
      file.append('PortfolioFrameworkContactFileName', data.portfolioFrameworkContactFileName)
      file.append('PortfolioFrameworkContactFile', data.portfolioFrameworkContactFile ?? '')
      file.append('PortfolioFrameworkContactFileImage', data.portfolioFrameworkContactFileImage ?? '')
      file.append('PortfolioTerm', data.portfolioTerm)
      if (data.portfolioTypeCode === 3) {
        file.append('PortfolioAffectedAdjustmentsId', data.portfolioAffectedAdjustmentsId ?? '')
      }
      if (data.portfolioTypeCode === 1) {
        file.append('PortfolioCurrencyId', data.portfolioCurrencyId ?? '')
      }
      file.append('PortfolioAccountingIdentification', data.portfolioAccountingIdentification ?? '')
      file.append('PortfolioOfficeNumber', data.portfolioOfficeNumber ?? '')
      file.append('Version', data.version)
      file.append('DateCreated', data.dateCreated)
      file.append('CreatedBy', data.createdBy)
      file.append('DateUpdated', data.dateUpdated)
      file.append('UpdatedBy', data.updatedBy)
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/portfolios/${id}`, file, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteAssociatedAccount(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .delete(`/associated_account/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
