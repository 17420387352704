import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSettlementTypesByCurrency(context, { PayeeId, CurrencyId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/settlement-types/get_by_currency', {
            params: {
              PayeeId,
              CurrencyId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSettlementTypesByCurrencyProduct(context, { CurrencyId, ProductId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/settlement-types/get_settlement_by_product_currency', {
            params: {
              CurrencyId,
              ProductId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
