import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    paymentTypes: {
      speiAccountTypeId: {
        display: false,
        value: 0,
      },
      speiAccountTypeName: {
        display: true,
        value: '',
        type: 'input',
        label: 'accountTypeSPEI',
      },
      speiAccountTypeCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'accountSPEI',
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      version: {
        display: false,
        value: '',
        type: '',
      },
    },
  },
  getters: {
    paymentTypes(state) {
      return state.paymentTypes
    },
  },
  mutations: {},
  actions: {
    getAccountTypes(
      _,
      {
        perPage, currentPage, filterByProductCode, orderBy = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/spei_account_types', {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              filterByIsActive: null,
              FilterByProductCode: filterByProductCode,
              OrderBy: orderBy,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchPaymentTypeById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/spei_account_types/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    savePaymentType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/spei_account_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updatePaymentType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/spei_account_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
