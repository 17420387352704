import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

const getLocalHome = state => {
  if (state.home) return state.home
  const home = localStorage.getItem('home')
  if (home) {
    return JSON.parse(home)
  }
  return {}
}

export default {
  namespaced: true,
  state: {
    openDay: null,
    currencies: null,
    home: null,
    currencyPairs: null,
  },
  getters: {
    openDay(state) {
      if (state.openDay) return state.openDay
      const openDay = sessionStorage.getItem('openDay')
      if (openDay) {
        return JSON.parse(openDay)
      }
      return null
    },
    currencyPairs(state) {
      return (
        state.currencyPairs || JSON.parse(sessionStorage.getItem('currencyPairs'))
      )
    },
    home(state) {
      return getLocalHome(state)
    },
    accountTypes(state) {
      return getLocalHome(state).accountTypes
    },
    countries(state) {
      return getLocalHome(state).countries
    },
    valuation(state) {
      return (
        state.home?.valuationTypes
        || JSON.parse(sessionStorage.getItem('home'))?.valuationTypes
      )
    },
    currencies(state) {
      return (getLocalHome(state).currencies || [])
    },
    currenciesTypes(state) {
      return (state.home?.currencyTypes || [])
    },
    speiOperationTypes(state) {
      return getLocalHome(state).speiOperationTypes
    },
    speiAccountTypes(state) {
      return getLocalHome(state).speiAccountTypes
    },
    speiPaymentTypes(state) {
      return getLocalHome(state).speiPaymentTypes
    },
    spidAccountTypes(state) {
      return getLocalHome(state).spidAccountTypes
    },
    spidClasifTransTypes(state) {
      return getLocalHome(state).spidClasifTransTypes
    },
    spidOperationTypes(state) {
      return getLocalHome(state).spidOperationTypes
    },
    spidPaymentTypes(state) {
      return getLocalHome(state).spidPaymentTypes
    },
    spidRelationTypes(state) {
      return getLocalHome(state).spidRelationTypes
    },
    branchBanks(state) {
      return getLocalHome(state).branchBanks
    },
    bankSettlementType(state) {
      return getLocalHome(state).bankSettlementType
    },
    brokers(state) {
      return getLocalHome(state).brokers
    },
    branchClients(state) {
      return getLocalHome(state).branchClients
    },
    personAdditionalDataTypes(state) {
      return getLocalHome(state).personAdditionalDataTypes
    },
    settlementTypes(state) {
      return getLocalHome(state).settlementTypes
    },
    economicActivityTypes(state) {
      return getLocalHome(state).economicActivityTypes
    },
    normalCollation(state) {
      return getLocalHome(state).normalCollation
    },
    tradeHeadges(state) {
      return getLocalHome(state).tradeHeadges
    },
    transactionTypes(state) {
      return getLocalHome(state).transactionTypes
    },
    portfolios(state) {
      return getLocalHome(state).portfolios
    },
    products(state) {
      return getLocalHome(state).products
    },
    resmercaSectorTypes(state) {
      return getLocalHome(state).resmercaSectorTypes
    },
    defaultCustomer(state) {
      return getLocalHome(state).defaultCustomer
    },
    paymentReasons(state) {
      return getLocalHome(state).paymentReasons
    },
    personTypes(state) {
      return getLocalHome(state).personTypes
    },
    relationship(state) {
      return getLocalHome(state).relationship
    },
    guaranteeAccountType(state) {
      return getLocalHome(state).guaranteeAccountType
    },
    guaranteeAccountStatus(state) {
      return getLocalHome(state).guaranteeAccountStatus
    },
    confirmationMethodTypes(state) {
      return getLocalHome(state).confirmationMethodTypes
    },
    branchOffices(state) {
      return getLocalHome(state).branchOffices
    },
    sectorOD(state) {
      return getLocalHome(state).sectorOD
    },
    orderTypes(state) {
      return getLocalHome(state).orderTypes
    },
    usoCFDI(state) {
      return getLocalHome(state).usoCFDI
    },
    contrapartTypes(state) {
      return getLocalHome(state).contrapartTypes
    },
    mercantilSocietyTypes(state) {
      return getLocalHome(state).mercantilSocietyTypes
    },
    parametersOrigen(state) {
      return getLocalHome(state).parametersOrigen
    },
    contractStatusTypes(state) {
      return getLocalHome(state).contractStatusTypes
    },
    terms(state) {
      return getLocalHome(state).terms
    },
    reportCustomerType(state) {
      return getLocalHome(state).reportCustomerType
    },
    reportOrigen(state) {
      return getLocalHome(state).reportOrigen
    },
    promocion(state) {
      return getLocalHome(state).promocion
    },
    regional(state) {
      return getLocalHome(state).regional
    },
    applications(state) {
      return getLocalHome(state).applications
    },
    employeeTypes(state) {
      return getLocalHome(state).employeeTypes
    },
    confirmationTransactionStatus(state) {
      return getLocalHome(state).confirmationTransactionStatus
    },
    confirmationTransactionTypes(state) {
      return getLocalHome(state).confirmationTransactionTypes
    },
    branchOfficesAdditionalDataType(state) {
      return getLocalHome(state).branchOfficesAdditionalDataType
    },
    webClientOptionFilter(state) {
      return getLocalHome(state).webClientOptionFilter
    },
    genderList(state) {
      return getLocalHome(state).genderList
    },
    portfolioTypes(state) {
      return getLocalHome(state).portfolioTypes
    },
    frequencyTypeList(state) {
      return getLocalHome(state).frequencyTypeList
    },

    currencyPairSignificantDigit(state) {
      return getLocalHome(state).currencyPairSignificantDigit.map(currencyDigit => ({
        value: currencyDigit.id,
        text: currencyDigit.name,
      }))
    },
  },
  mutations: {
    SET_OPEN_DAY(state, payload) {
      state.openDay = payload
      sessionStorage.setItem('openDay', JSON.stringify(payload))
    },
    SET_CURRENCIES(state, payload) {
      state.currencies = payload
      sessionStorage.setItem('currencies', JSON.stringify(payload))
    },
    SET_CURRENCY_PAIRS(state, payload) {
      state.currencyPairs = payload
      sessionStorage.setItem('currencyPairs', JSON.stringify(payload))
    },
    SET_HOME(state, payload) {
      state.home = payload
      localStorage.setItem('home', JSON.stringify(payload))
      const now = new Date()
      const cacheDays = process.env.HOME_CACHE_DAYS || 1
      const ttl = now.getTime() + (cacheDays * 24 * 60 * 60 * 1000) // set TTL to 24 hours from now
      localStorage.setItem('homeTTL', ttl)
    },
    LOAD_HOME(state) {
      state.home = getLocalHome(state)
    },
  },
  actions: {
    getCurrencyName(context, currencyId) {
      const currency = context.getters.currencies.find(e => e.id === currencyId)
      if (currency) {
        return currency.name
      }
      return ''
    },
    getOpenDay(context) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_day')
          .then(response => {
            context.commit('SET_OPEN_DAY', response.data)
            resolve(response.data)
          })
          .catch(error => {
            context.commit('SET_OPEN_DAY', '{ "isActive": false }')
            reject(error)
          })
      })
    },

    getHome(context) {
      return new Promise((resolve, reject) => {
        const { home } = context.getters
        const now = Date.now()
        const homeTTL = localStorage.getItem('homeTTL') || now - (1 * 60 * 1000)
        if (home === undefined || home === null || Object.entries(home).length === 0 || homeTTL <= now) {
          sigloNetAxiosInstance
            .get('/home')
            .then(response => {
              context.commit('SET_HOME', response.data)
              resolve(response.data)
            })
            .catch(error => {
              context.commit('SET_HOME', {})
              reject(error)
            })
        } else {
          context.commit('LOAD_HOME')
          resolve(context.state.home)
        }
      })
    },

    fetchCurrencyPairs({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (sessionStorage.getItem('currencyPairs')) {
          resolve(getters.currencyPairs)
          return
        }
        sigloNetAxiosInstance
          .get('/currency-pairs?Orderby[FilterByCurrencyId]=DESC', {
            params: {
              FilterByIsActive: true,
              PerPage: 100,
            },
          })
          .then(response => {
            commit('SET_CURRENCY_PAIRS', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchStatesByCountry(context, { CountryId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/states/get-by-country', {
            params: {
              CountryId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCitiesByState(context, { StateId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/cities/get_by_state', {
            params: {
              StateId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCurrencyPairsById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/currency-pairs/get_by_currency_id', {
            params: {
              id,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchCurrencyPair(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/currency-pairs', {
            params: {
              FilterByIsActive: true,
              PerPage: 100,
              FilterByCurrencyId: id,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCurrencyPairDetails(_, { currencyPairId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/currency-pairs/${currencyPairId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
