import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import init from './initialFunctions/init'
import appConfig from './app-config'
import boards from './boards/boards'
import clients from './clients'
import verticalMenu from './vertical-menu'
import prices from './prices/prices'
import customers from './customers/customers'
import portfolios from './portfolios/portfolios'
import employees from './employees/employees'
import users from './users/users'
import persons from './persons/persons'
import operations from './operations/operations'
import orders from './orders/orders'
import payeeSettlement from './payees/payeeSettlement'
import personsContact from './persons/personsContact'
import payeeCompliance from './payees/payeeCompliance'
import settlementsTypes from './settlements/settlementsTypes'
import banks from './banks/banks'
import formValidations from './formValidations/formValidations'
import exchangeRate from './exchangeRate/exchangeRate'
import pricing from './pricing/pricing'
import openDay from './openDay/openDay'
import openPosition from './openPosition/openPosition'
import reports from './reports/reports'
import branchOffice from './branchOffice/branchOffice'
import products from './products/products'
import currencyPairs from './currencyPairs/currencyPairs'
import currencies from './currencies/currencies'
import speiAccountType from './spei/speiAccountType'
import speiPaymentTypes from './spei/speiPaymentTypes'
import speiOperationTypes from './spei/speiOperationTypes'
import institutions from './institutions/institutions'
import spidAccountType from './spid/spidAccountType'
import spidPaymentTypes from './spid/spidPaymentTypes'
import spidOperationTypes from './spid/spidOperationTypes'
import spidRelationType from './spid/spidRelationType'
import spidClasificationType from './spid/spidClasificationType'
import holidays from './holidays/holidays'
import webPrices from './webPrices/webPrices'
import countries from './countries/countries'
import dayParams from './dayParams/dayParams'
import books from './books/books'
import paymentMethod from './paymentMethod/paymentMethod'
import bnfBank from './bnfBank/bnfBank'
import profile from './profileInfo/profile'
import webCurrencyLimit from './webCurrencyLimit/webCurrencyLimit'
import transactionType from './transactionType/transactionType'
import broker from './broker/broker'
import webClient from './webClient/webClient'
import banksSettlementsTypes from './settlements/banksSettlementsTypes'
import address from './addressBank/address'
import webParams from './webParams/webParams'
import customerUser from './customerUser/customerUser'
import globalPosition from './globalPosition/gloablPosition'
import news from './news/news'
import associateSwapOperations from './associateSwapOperations/associateSwapOperations'
import scheduledTask from './scheduledTask/scheduledTask'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    init,
    appConfig,
    boards,
    verticalMenu,
    clients,
    prices,
    customers,
    portfolios,
    employees,
    users,
    persons,
    operations,
    orders,
    payeeSettlement,
    personsContact,
    payeeCompliance,
    settlementsTypes,
    banks,
    formValidations,
    exchangeRate,
    pricing,
    openDay,
    openPosition,
    reports,
    branchOffice,
    products,
    currencyPairs,
    speiAccountType,
    speiPaymentTypes,
    speiOperationTypes,
    institutions,
    currencies,
    spidAccountType,
    spidPaymentTypes,
    spidOperationTypes,
    spidRelationType,
    spidClasificationType,
    holidays,
    countries,
    webPrices,
    dayParams,
    books,
    paymentMethod,
    bnfBank,
    profile,
    webCurrencyLimit,
    transactionType,
    broker,
    webClient,
    banksSettlementsTypes,
    address,
    webParams,
    customerUser,
    globalPosition,
    news,
    associateSwapOperations,
    scheduledTask,
  },
  strict: process.env.DEV,
})
