import sigloNetAxiosInstance from '@/utils/sigloNetAxios'
import { sortRemote } from '@/helpers'

export default {
  namespaced: true,
  state: {
    bankSettlement: {
      bankCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'bankCode',
      },
      bankDescription: {
        display: true,
        value: '',
        type: 'input',
        label: 'description',
      },
      bankName: {
        display: true,
        value: '',
        type: 'input',
        label: 'bank',
      },
      bankSettlementTypeId: {
        display: false,
        value: 0,
        type: 'numInput',
        label: 'bankSettlementTypeId',
      },
      banxicoKey: {
        display: true,
        value: 0,
        type: 'numInput',
        label: 'numCustumerBanxico',
        precision: 0,
        isDecimal: false,
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      settlmenTransfer: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'settlmenTransfer',
      },
      version: {
        display: false,
        value: '',
        type: 'input',
      },
    },
  },
  getters: {
    getBankSettlement(state) {
      return state.bankSettlement
    },
  },
  mutations: {},
  actions: {
    fetchBanksSettlementTypes(_, {
      filterByProductCode, orderBy = null, perPage, currentPage,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/banks-settlement-type/index_get_banks-settlement-type?${sortRemote(orderBy)}`, {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: true,
              FilterByProductCode: filterByProductCode,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchBanksSettlementTypesById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/banks-settlement-type/show_banks-settlement-type${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateBanksSettlementTypesById(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/banks-settlement-type/update_banks-settlement-type', {
            bankSettlementTypeId: data.bankSettlementTypeId,
            bankName: data.bankName,
            bankDescription: data.bankDescription,
            bankCode: data.bankCode,
            settlmenTransfer: data.settlmenTransfer,
            banxicoKey: data.banxicoKey,
            isActive: data.isActive,
            version: data.version,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createBanksSettlementTypesById(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/banks-settlement-type/create_banks-settlement-type', {
            bankSettlementTypeId: data.bankSettlementTypeId,
            bankName: data.bankName,
            bankDescription: data.bankDescription,
            bankCode: data.bankCode,
            settlmenTransfer: data.settlmenTransfer,
            banxicoKey: data.banxicoKey,
            isActive: data.isActive,
            version: data.version,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchBankAccountSettlementTypesById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          // eslint-disable-next-line no-irregular-whitespace
          .get(`/bank_account_settlement_type/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteBankAccountSettlementTypesById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          // eslint-disable-next-line no-irregular-whitespace
          .delete(`/bank_account_settlement_type/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateBankAccountSettlementTypesById(_, { id, data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          // eslint-disable-next-line no-irregular-whitespace
          .put(`/bank_account_settlement_type/${id}`, {
            bankAccountSettlementTypeId: data.bankAccountSettlementTypeId,
            bankSettlementTypeId: data.bankSettlementTypeId,
            accountTypeCode: data.accountTypeCode,
            account: data.account,
            isActive: data.isActive,
            isForeignBank: data.isForeignBank,
            version: data.version,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createBankAccountSettlementTypesById(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          // eslint-disable-next-line no-irregular-whitespace
          .post('/bank_account_settlement_type', {
            bankAccountSettlementTypeId: data.bankAccountSettlementTypeId || 0,
            bankSettlementTypeId: data.bankSettlementTypeId,
            accountTypeCode: data.accountTypeCode,
            account: data.account,
            isActive: data.isActive,
            isForeignBank: data.isForeignBank,
            version: data.version,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
