import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    updateWarrantyAccountsTable: false,
  },
  getters: {
    updateWarrantyAccountsTable(state) {
      return state.updateWarrantyAccountsTable
    },
  },
  mutations: {
    SET_WARRANTY_ACCOUNTS(state) {
      state.updateWarrantyAccountsTable = !state.updateWarrantyAccountsTable
    },
  },
  actions: {
    fetchCustomers(_, { customer, contractCode }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/customers/find-customer-for-logged-employee', {
            params: {
              customer,
              contractCode,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCustomer(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCustomersAuthorizedPortfolios(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customer_authorized_portfolios/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    searchCustomers(_, {
      contractCode, customer, numBanquero, RFC, claveOmni,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/customers/find-customers', {
            params: {
              contractCode, customer, numBanquero, RFC, claveOmni,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCustomerByContractId(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_by_contractid/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCustomerDealTracker(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_customer_dealtracker/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveCustomerDealTracker(_, dealTrackerData) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/customers/save_customer_dealtracker', dealTrackerData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveCustomersAuthorizedPortfolios(_, customerData) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/customer_authorized_portfolios/save', customerData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateCustomer(_, { customerData }) {
      const file = new FormData()
      file.append('ClientId', customerData.clientId)
      // file.append('ClientCode', customerData.clientCode)
      // file.append('IsWebClient', customerData.isWebClient)
      // file.append('ClientType', customerData.clientType)
      file.append('IsActive', customerData.isActive)
      file.append('Version', customerData.version)
      file.append('Person.PersonId', customerData.person.personID)
      file.append('Person.RFC', customerData.person.rfc ?? '')
      file.append('Person.Name', customerData.person.name)
      file.append('Person.EconomicActivityTypeCode', customerData.person.economicActivityTypeCode)
      file.append('Person.CountryId', customerData.person.countryId)
      file.append('Person.IsLegalPerson', customerData.person.isLegalPerson)
      file.append('Person.IsForeignCitizen', customerData.person.isForeignCitizen)
      file.append('Person.ImageFile', customerData.person.imageFile)
      file.append('Person.BanxicoSectorTypeId', customerData.person.banxicoSectorTypeId)
      file.append('Person.StateId', customerData.person.stateId)
      file.append('Person.ResmercaSectorId', customerData.person.resmercaSectorId)
      file.append('Person.IsActive', customerData.person.isActive)
      file.append('Person.Version', customerData.person.version)
      if (customerData.person.isLegalPerson) {
        file.append('Person.LegalPersonFiscalInformation.LegalPersonId', customerData.person.legalPersonFiscalInformation.legalPersonId)
        file.append('Person.LegalPersonFiscalInformation.PersonId', customerData.person.legalPersonFiscalInformation.personId)
        file.append('Person.LegalPersonFiscalInformation.LegalPersonShortName', customerData.person.legalPersonFiscalInformation.legalPersonShortName)
        // file.append('Person.LegalPersonFiscalInformation.FiscalBusinessName', customerData.person.legalPersonFiscalInformation.fiscalBusinessName)
        file.append('Person.LegalPersonFiscalInformation.EstablishmentDate', customerData.person.legalPersonFiscalInformation.establishmentDate)
        file.append('Person.LegalPersonFiscalInformation.WebPage', customerData.person.legalPersonFiscalInformation.webPage)
        file.append('Person.LegalPersonFiscalInformation.IsActive', customerData.person.legalPersonFiscalInformation.isActive)
        file.append('Person.LegalPersonFiscalInformation.Version', customerData.person.legalPersonFiscalInformation.version)
      } else {
        file.append('Person.NaturalPersonFiscalInformation.NaturalPersonId', customerData.person.naturalPersonFiscalInformation.naturalPersonId)
        file.append('Person.NaturalPersonFiscalInformation.PersonId', customerData.person.naturalPersonFiscalInformation.personId)
        file.append('Person.NaturalPersonFiscalInformation.PaternalSurname', customerData.person.naturalPersonFiscalInformation.paternalSurname)
        file.append('Person.NaturalPersonFiscalInformation.MaternalSurname', customerData.person.naturalPersonFiscalInformation.maternalSurname)
        file.append('Person.NaturalPersonFiscalInformation.Birthdate', customerData.person.naturalPersonFiscalInformation.birthdate)
        file.append('Person.NaturalPersonFiscalInformation.BirthCountryID', customerData.person.naturalPersonFiscalInformation.birthCountryID)
        file.append('Person.NaturalPersonFiscalInformation.IsActive', customerData.person.naturalPersonFiscalInformation.isActive)
        file.append('Person.NaturalPersonFiscalInformation.Version', customerData.person.naturalPersonFiscalInformation.version)
      }
      for (let i = 0; i < customerData.person.additionalData.length; i++) {
        file.append(`Person.AdditionalData[${i}].PersonAdditionalDataId`, customerData.person.additionalData[i].personAdditionalDataId)
        file.append(`Person.AdditionalData[${i}].PersonId`, customerData.person.additionalData[i].personId)
        file.append(`Person.AdditionalData[${i}].AdditionalDataCode`, customerData.person.additionalData[i].additionalDataCode)
        file.append(`Person.AdditionalData[${i}].AdditionalDataValue`, customerData.person.additionalData[i].additionalDataValue)
        file.append(`Person.AdditionalData[${i}].IsActive`, customerData.person.additionalData[i].isActive)
      }
      file.append('Contract.ContractId', customerData.contract.contractId)
      file.append('Contract.ContractCode', customerData.contract.contractCode)
      file.append('Contract.IsPermanentContract', customerData.contract.isPermanentContract)
      file.append('Contract.UserCounterpartId', customerData.contract.userCounterpartId)
      file.append('Contract.CustomerId', customerData.contract.customerId)
      file.append('Contract.IsActive', customerData.contract.isActive)
      file.append('Contract.Version', customerData.contract.version)
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/customers/', file, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getBanxicoSectorTypesByPersonType(_, { PersonFiscalType }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/banxico_sector_types/get_by_person_type/${PersonFiscalType}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCommission(_, { contractId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_commission/${contractId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCustomerSpreads(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_spreads/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveCommission(_, { customerData }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/customers/commission', customerData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateCustomerSpreads(_, spreadData) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/customers/spreads', spreadData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getBeneficiaries(_, { contractId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_cutomer_beneficiaries/${contractId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveCustomerGuaranteeAccount(_, { guaranteeAccount }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/customers/save_Customer_guarantee_Account', guaranteeAccount)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveBeneficiaries(_, { beneficiaryData }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/customers/save_beneficiaries', beneficiaryData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCustomerGuaranteeAccount(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_Customer_guarantee_Account/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteDealTracker(_, { customerDealTrackerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .delete(`/customers/delete_customer_dealtracker/${customerDealTrackerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAsiggnedPromoters(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_asiggned_promoters/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getParams(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_parameters_siglo_by_contractid/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAccountByCustomer(_, { customerId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_account_by_customer/${customerId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveSigloParams(_, { customerId, value, isBanxico = false }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(`/customers/save_parameters_siglo/${customerId}`, value, {
            params: {
              isBanxico,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveAccountData(_, { beneficiariesData }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/customers/update_payee_settlementType', beneficiariesData)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getGuaranteeccountById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customers/get_guarantee_account_by_id/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
