<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <v-idle
      v-show="false"
      v-if="isUserLoggedIn()"
      :duration="1800"
      :reminders="[900]"
      @idle="logout"
      @remind="openInactivityModal"
    />
    <b-modal
      id="inactivityModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <div>
        <div>{{ $t("sessionExpiring") }}</div>
        <span class="text-primary">{{ timer }}</span>
      </div>
      <template #modal-footer="{ ok, hide }">
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="() => {hide(); logout()}"
        >
          {{ $t("logout") }}
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="() => {ok();cancelIdle()}"
        >
          {{ $t("keepSession") }}
        </b-button>
      </template>
    </b-modal>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
// import { provideToast } from 'vue-toastification/composition'
import { watch, onMounted } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
// eslint-disable-next-line
import useJwt from '@/auth/jwt/useJwt'
import { isUserLoggedIn } from '@/auth/utils'
import Vidle from 'v-idle'
import authService from '@/utils/authorize-service'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    // user events
    Vidle,
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    if (window.location.pathname === '/') {
      store.dispatch('init/getOpenDay').catch(() => {})
    }

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    // provideToast({
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   closeButton: false,
    //   icon: false,
    //   timeout: 3000,
    //   transition: 'Vue-Toastification__fade',
    // })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    onMounted(() => {
    })

    return {
      skinClasses,
      isUserLoggedIn,
    }
  },
  data() {
    return {
      loading: false,
      isModalOpen: false,
      timer: 0,
      startTimer: false,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch: {
    timer: {
      handler() {
        if (this.startTimer) {
          setTimeout(() => {
            if (this.timer > 1) {
              // eslint-disable-next-line
              this.timer--
            }
          }, 1000)
          if (this.timer === 1) {
            this.logout()
          }
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods: {
    openInactivityModal(time) {
      if (this.startTimer) return
      this.isModalOpen = true
      this.$bvModal.show('inactivityModal')
      this.timer = time
      this.startTimer = true
    },
    cancelIdle() {
      this.$bvModal.hide('inactivityModal')
      this.startTimer = false
      this.isModalOpen = false
      this.timer = 0
    },
    async logout() {
      sessionStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      sessionStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      sessionStorage.removeItem('userData')
      sessionStorage.removeItem('sigloSwagger')
      sessionStorage.removeItem('openDay')
      sessionStorage.removeItem('storedWidgets')
      sessionStorage.removeItem('storedBoards')
      authService.signOut()
      this.cancelIdle()
    },
  },
}
</script>

<style>
#activityCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
</style>
