import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

const AbortFromStore = 30000

const contentTypesMapping = {
  csv: 'blob',
  pdf: 'json',
  default: 'text',
}

const openWindowBlank = (response, ContentType) => {
  if (ContentType === 'html') {
    const nuevaVentana = window.open()
    nuevaVentana.document.open('noopener=true')
    nuevaVentana.document.write(response.data)
    nuevaVentana.opener = null
    nuevaVentana.document.close()
  }
}

const creatingCustomRoute = (ContentType, PortfoliosIds, To, endpoint) => {
  let route = `/${endpoint}/get.${ContentType}?${PortfoliosIds.map(
    (n, index) => `PortfoliosIds[${index}]=${n}`,
  ).join('&')}`

  if (ContentType === 'pdf' && To.length > 0) {
    route = `${route}&To=${To.join(',')}`
  }

  return route
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTransactionOperationReport(
      _,
      {
        StartDate,
        EndDate,
        CurrencyId,
        ContentType,
        PortfoliosIds,
        HasOnlyWeb,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(
            `/transaction_operation_report/get.${ContentType}?${PortfoliosIds.map(
              (n, index) => `PortfoliosIds[${index}]=${n}`,
            ).join('&')}`,
            {
              params: {
                StartDate,
                EndDate,
                CurrencyId,
                HasOnlyWeb,
              },
              filename: `OperacionesFechaOperacion.${ContentType}`,
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            },
          )
          .then(response => {
            if (ContentType === 'html') {
              const nuevaVentana = window.open()
              nuevaVentana.document.open('noopener=true')
              nuevaVentana.document.write(response.data)
              nuevaVentana.opener = null
              nuevaVentana.document.close()
              resolve(response.data)
            }
            if (ContentType === 'pdf') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchValueReport(
      _,
      {
        StartDate,
        EndDate,
        CurrencyId,
        ContentType,
        PortfoliosIds,
        To,
      },
    ) {
      return new Promise((resolve, reject) => {
        const route = creatingCustomRoute(ContentType, PortfoliosIds, To, 'transaction_value_report')
        sigloNetAxiosInstance
          .get(route,
            {
              params: {
                StartDate,
                EndDate,
                CurrencyId,
              },
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
              responseType: contentTypesMapping[ContentType],
              filename: `OperacionesFechaVencimiento.${ContentType}`,
            })
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchConsecutiveReport(
      _,
      {
        StartDate, EndDate, ContentType, PortfoliosIds,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(
            `/transaction_consecutive_report/get.${ContentType}?${PortfoliosIds.map(
              (n, index) => `PortfoliosIds[${index}]=${n}`,
            ).join('&')}`,
            {
              params: {
                StartDate,
                EndDate,
              },
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
              filename: `TransactionConsecutive.${ContentType}`,
            },
          )
          .then(response => {
            if (ContentType === 'html') {
              const nuevaVentana = window.open()
              nuevaVentana.document.open('noopener=true')
              nuevaVentana.document.write(response.data)
              nuevaVentana.opener = null
              nuevaVentana.document.close()
              resolve(response.data)
            }
            if (ContentType === 'pdf') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTransactionOrigin(
      _,
      {
        StartDate, EndDate, ContentType, PortfoliosIds, To,
      },
    ) {
      let route = `/transaction_with_origin_report/get.${ContentType}?${PortfoliosIds.map(
        (n, index) => `PortfoliosIds[${index}]=${n}`,
      ).join('&')}`

      if (ContentType === 'pdf' && To.length > 0) {
        route = `${route}&To=${To.join(',')}`
      }
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(route,
            {
              params: {
                StartDate,
                EndDate,
              },
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
              // eslint-disable-next-line no-nested-ternary
              responseType: contentTypesMapping[ContentType],
              filename: `OperacionesConOrigen.${ContentType}`,
            })
          .then(response => {
            if (ContentType === 'html') {
              openWindowBlank(response, ContentType)
              resolve(response.data)
            }
            if (ContentType === 'pdf') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchAuditReport(_,
      {
        StartDate,
        EndDate,
        ContentType,
        PortfoliosIds,
        To,
      }) {
      return new Promise((resolve, reject) => {
        const route = creatingCustomRoute(ContentType, PortfoliosIds, To, 'audit_report')
        sigloNetAxiosInstance
          .get(route,
            {
              params: {
                StartDate,
                EndDate,
              },
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
              // eslint-disable-next-line no-nested-ternary
              responseType: contentTypesMapping[ContentType],
              filename: `OperacionesFechaVencimiento.${ContentType}`,
            })
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchObligationToReceiveAndDeliver(
      _,
      {
        StartDate,
        EndDate,
        CurrencyId,
        ContentType,
        PortfoliosIds,
        HasOnlyWeb,
        To,
      },
    ) {
      // obligation_receive_deliver_report
      return new Promise((resolve, reject) => {
        const route = creatingCustomRoute(ContentType, PortfoliosIds, To, 'obligation_receive_deliver_report')
        sigloNetAxiosInstance
          .get(route,
            {
              params: {
                StartDate,
                EndDate,
                CurrencyId,
                HasOnlyWeb,
              },
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
              responseType: contentTypesMapping[ContentType],
              filename: `ObligationReceiveDeliver.${ContentType}`,
            })
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchObligationReceiveDeliverReport(
      _,
      {
        StartDate,
        EndDate,
        CurrencyId,
        ContentType,
        PortfoliosIds,
        HasOnlyWeb,
        To,
      },
    ) {
      // obligation_receive_deliver_report
      return new Promise((resolve, reject) => {
        const route = creatingCustomRoute(ContentType, PortfoliosIds, To, 'obligation_receive_deliver_report')
        sigloNetAxiosInstance
          .get(route,
            {
              params: {
                StartDate,
                EndDate,
                CurrencyId,
                HasOnlyWeb,
              },
              filename: `ObligationReceiveDeliverByClient.${ContentType}`,
              responseType: contentTypesMapping[ContentType],
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            })
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTransactionStatusReport(
      _,
      {
        StartDate,
        EndDate,
        ContentType,
        EmployeeIds,
        HasOnlyWeb,
        SucursalId,
        RegionalId,
        Banquero,
        AllPromoters,
        To,
      },
    ) {
      return new Promise((resolve, reject) => {
        let emails = ''
        if (To) {
          emails = `&To=${To.join(',')}`
        }
        sigloNetAxiosInstance
          .get(
            `/transaction_status_report/get.${ContentType}?${EmployeeIds.map(
              (n, index) => `EmployeeIds[${index}]=${n}${emails}`,
            ).join('&')}`,
            {
              params: {
                StartDate,
                EndDate,
                HasOnlyWeb,
                SucursalId,
                RegionalId,
                Banquero,
                AllPromoters,
              },
              filename: `StatusReport.${ContentType}`,
              responseType: contentTypesMapping[ContentType],
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            },
          )
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getBranchOfficeByRegion(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/branch_office/get_by_region/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchOrderHistory(
      _,
      {
        StartDate,
        EndDate,
        ContentType,
        StatusId,
        OriginId,
        ClientIds,
        PromotersIds,
        AllClients,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/orders/get.${ContentType}?${PromotersIds.map((n, index) => `PromotersIds[${index}]=${n}`).join('&')}&${ClientIds.map((n, index) => `ClientIds[${index}]=${n}`).join('&')}`, {
            params: {
              StartDate,
              EndDate,
              StatusId,
              OriginId,
              AllClients,
            },
            filename: `OrderHistory.${ContentType}`,
            timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
          })
          .then(response => {
            if (ContentType === 'html') {
              const nuevaVentana = window.open()
              nuevaVentana.document.open('noopener=true')
              nuevaVentana.document.write(response.data)
              nuevaVentana.opener = null
              nuevaVentana.document.close()
              resolve(response.data)
            }
            if (ContentType === 'pdf') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchClientReassignment(_,
      {
        StartDate,
        EndDate,
        ContentType,
      }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customer_reassignment_report/get.${ContentType}?`, {
            params: {
              StartDate,
              EndDate,
            },
            filename: `ClientReassignment.${ContentType}`,
            timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCustomerReport(
      _,
      {
        Promotion, StateId, CityId, CustomersIds, PromotersIds, ContentType,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(
            `/customers/get.${ContentType}?${PromotersIds.map(
              (n, index) => `PromotersIds[${index}]=${n}`,
            ).join('&')}&${CustomersIds.map(
              (n, index) => `CustomersIds[${index}]=${n}`,
            ).join('&')}`,
            {
              params: {
                Promotion,
                StateId,
                CityId,
              },
              // responseType: 'blob',
              filename: `CustomerReport.${ContentType}`,
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            },
          )
          .then(response => {
            if (ContentType === 'html') {
              const nuevaVentana = window.open()
              nuevaVentana.document.open('noopener=true')
              nuevaVentana.document.write(response.data)
              nuevaVentana.opener = null
              nuevaVentana.document.close()
              resolve(response.data)
            }
            if (ContentType === 'pdf') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPromoterComissionsReport(
      _,
      {
        StartDate,
        EndDate,
        RegionalId,
        SucursalId,
        PortfoliosIds,
        PromotersIds,
        NoBanquero,
        HasOnlyWeb,
        ContentType,
        To,
      },
    ) {
      return new Promise((resolve, reject) => {
        let route
        if (To && To.length > 0) {
          route = `/promoter_comissions_report/get.${ContentType}?${PortfoliosIds.map(
            (n, index) => `PortfoliosIds[${index}]=${n}`,
          ).join('&')}&${PromotersIds.map(
            (n, index) => `PromotersIds[${index}]=${n}`,
          ).join('&')}&To=${To.join(',')}`
        } else {
          route = `/promoter_comissions_report/get.${ContentType}?${PortfoliosIds.map(
            (n, index) => `PortfoliosIds[${index}]=${n}`,
          ).join('&')}&${PromotersIds.map(
            (n, index) => `PromotersIds[${index}]=${n}`,
          ).join('&')}`
        }
        sigloNetAxiosInstance
          .get(route,
            {
              params: {
                StartDate,
                EndDate,
                RegionalId,
                SucursalId,
                NoBanquero,
                HasOnlyWeb,
              },
              responseType: contentTypesMapping[ContentType],
              filename: `OperacionesFechaVencimiento.${ContentType}`,
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            })
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPromoterReport(
      _,
      {
        StartDate, EndDate, ContentType, PortfoliosIds, PromotersIds,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(
            `/promoter_comissions_summary_report/get.${ContentType}?${PortfoliosIds.map(
              (n, index) => `PortfoliosIds[${index}]=${n}`,
            ).join('&')}&${PromotersIds.map(
              (n, index) => `PromotersIds[${index}]=${n}`,
            ).join('&')}`,
            {
              params: {
                StartDate,
                EndDate,
                ContentType,
              },
              filename: `ComissionSummaryReport.${ContentType}`,
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            },
          )
          .then(response => {
            if (ContentType === 'html') {
              const nuevaVentana = window.open()
              nuevaVentana.document.open('noopener=true')
              nuevaVentana.document.write(response.data)
              nuevaVentana.opener = null
              nuevaVentana.document.close()
              resolve(response.data)
            }
            if (ContentType === 'pdf') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchComissionsReport(
      _,
      {
        StartDate,
        EndDate,
        CustomersIds,
        AllClients,
        ContentType,
        To,
      },
    ) {
      let route
      if (To && To.length > 0) {
        route = `/customers_comissions_report/get.${ContentType}?${CustomersIds.map(
          (n, index) => `CustomersIds[${index}]=${n}`,
        ).join('&')}&To=${To.join(',')}`
      } else {
        route = `/customers_comissions_report/get.${ContentType}?${CustomersIds.map(
          (n, index) => `CustomersIds[${index}]=${n}`,
        ).join('&')}`
      }
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(route,
            {
              params: {
                StartDate,
                EndDate,
                AllClients,
              },
              responseType: contentTypesMapping[ContentType],
              filename: `ComissionsReport.${ContentType}`,
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            })
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCustomerStatusReport(
      _,
      {
        ContentType,
        ClientIds,
        IsAllSelected,
        To = [''],
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/customer_status_report/get.${ContentType}?${ClientIds.map((n, index) => `ClientIds[${index}]=${n}`).join('&')}&To=${To.join(',')}`, {
            params: {
              IsAllSelected,
            },
            // responseType: 'blob',
            timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            responseType: contentTypesMapping[ContentType],
            filename: `customer_status_report.${ContentType}`,
          })
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getSystemLogReport(_,
      {
        StartDate,
        EndDate,
        AplicationName,
      }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/system_log_report/get', {
            params: {
              StartDate,
              EndDate,
              AplicationName,
            },
            timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
          })
          .then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
      })
    },

    getSystemLogReportCsv(
      _,
      {
        StartDate,
        EndDate,
        AplicationName,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/system_log_report/get.csv', {
            params: {
              StartDate,
              EndDate,
              AplicationName,
            },
            timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            filename: 'SystemLogReport.csv',
            blob: 'SystemLogReport.csv',
            responseType: 'blob',
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchTradedTransactionCustomerReport(
      _,
      {
        StartDate,
        EndDate,
        CurrencyId,
        CustomerType,
        PortfoliosIds,
        ClientIds,
        IsAllSelected,
        Origin,
        ContentType,
        To,
      },
    ) {
      return new Promise((resolve, reject) => {
        let route
        if (To && To.length > 0) {
          route = `/traded_transaction_customer_report/get.${ContentType}?${PortfoliosIds.map(
            (n, index) => `PortfoliosIds[${index}]=${n}`,
          ).join('&')}&${ClientIds.map(
            (n, index) => `ClientIds[${index}]=${n}`,
          ).join('&')}&To=${To.join(',')}`
        } else {
          route = `/traded_transaction_customer_report/get.${ContentType}?${PortfoliosIds.map(
            (n, index) => `PortfoliosIds[${index}]=${n}`,
          ).join('&')}&${ClientIds.map(
            (n, index) => `ClientIds[${index}]=${n}`,
          ).join('&')}`
        }
        sigloNetAxiosInstance
          .get(route,
            {
              params: {
                StartDate,
                EndDate,
                CurrencyId,
                CustomerType,
                IsAllSelected,
                Origin,
              },
              filename: `TradedTransactionCustomerDate.${ContentType}`,
              responseType: contentTypesMapping[ContentType],
              timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
            })
          .then(response => {
            openWindowBlank(response, ContentType)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
