import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

const AbortFromStore = 30000

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCurrenciesPosition(_, { portfolioId, currencyId }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_position/get_currencies_position', {
            params: {
              portfolioId, currencyId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTradedPositionDatesByUser(_, {
      PortfolioId, StartDate, EndDate, CurrencyPairId,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_position/traded_position_dates_by_user', {
            params: {
              PortfolioId, StartDate, EndDate, CurrencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTradedPositionDetailByUser(_, {
      PortfolioId, TradeDate, CurrencyPairId,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_position/traded_position_detail_by_user', {
            params: {
              PortfolioId, TradeDate, CurrencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getExpirationPositionByUser(_, {
      PortfolioId, StartDate, EndDate, CurrencyPairId,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/open_position/expiration_position_by_user', {
            params: {
              PortfolioId, StartDate, EndDate, CurrencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateValuationExchangeRate(_, {
      currencyPairId, RateValue,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post(`/open_position/update_valuation_exchange_rate/${currencyPairId}`,
            {},
            {
              params: {
                RateValue,
              },
            })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    reportExpirationPositionDetail(_, {
      PortfolioId, TradeDate, CurrencyPairId, ContentType, tab, To,
    }) {
      ContentType = ContentType ? `.${ContentType}` : ''
      return new Promise((resolve, reject) => {
        let route = `/open_position/expiration_position_detail_by_user${ContentType}`
        if (ContentType === '.pdf' && To.length > 0) {
          route = `${route}?To=${To.join(',')}`
        }
        sigloNetAxiosInstance
          .get(route, {
            params: {
              PortfolioId, TradeDate, CurrencyPairId,
            },
            responseType: ContentType === '.csv' ? 'blob' : null,
            filename: ContentType ? `ExpirationPositionDetailReport${ContentType}` : null,
            timeout: ContentType ? AbortFromStore || process.env.TIME_ABORT_REQUEST : null,
          })
          .then(response => {
            if (ContentType) {
              if (tab === 'html' || tab === undefined) {
                const nuevaVentana = window.open()
                nuevaVentana.document.open('noopener=true')
                nuevaVentana.document.write(response.data)
                nuevaVentana.opener = null
                nuevaVentana.document.close()
                resolve(response.data)
              }
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    reportTradedPositionDetail(_, {
      PortfolioId, TradeDate, CurrencyPairId, ContentType = 'json', tab,
    }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/open_position/traded_position_detail_by_user.${ContentType}`, {
            params: {
              PortfolioId, TradeDate, CurrencyPairId,
            },
            // responseType: 'blob',
            filename: `ExpirationPositionDetailReport.${ContentType}`,
            timeout: AbortFromStore || process.env.TIME_ABORT_REQUEST,
          })
          .then(response => {
            if (tab === 'html' || tab === undefined) {
              const nuevaVentana = window.open()
              nuevaVentana.document.open('noopener=true')
              nuevaVentana.document.write(response.data)
              nuevaVentana.opener = null
              nuevaVentana.document.close()
              resolve(response.data)
            }
            if (tab === 'pdf') {
              resolve(response.data)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPositionTransfer() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/position_transfer')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    savePositionTransfer(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/position_transfer', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
