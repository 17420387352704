import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

export default {
  namespaced: true,
  state: {
    paymentTypes: {
      speiPaymentTypeId: {
        display: false,
        value: 0,
      },
      speiPaymentTypeName: {
        display: true,
        value: '',
        type: 'input',
        label: 'paymentType',
      },
      speiPaymentTypeCode: {
        display: true,
        value: '',
        type: 'input',
        label: 'code',
      },
      isActive: {
        display: true,
        value: true,
        type: 'checkbox',
        label: 'active',
      },
      version: {
        display: false,
        value: '',
      },
      isDefaultTPBenef: {
        display: false,
        value: true,
      },
      isDefaultInter: {
        display: false,
        value: true,
      },
    },
  },
  getters: {
    paymentTypes(state) {
      return state.paymentTypes
    },
  },
  mutations: {},
  actions: {
    getPaymentTypes(
      _,
      {
        perPage, currentPage, filterByProductCode, orderBy = null, filterByIsActive = null,
      },
    ) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/spei_payment_types', {
            params: {
              PerPage: perPage,
              CurrentPage: currentPage,
              FilterByIsActive: filterByIsActive,
              FilterByProductCode: filterByProductCode,
              OrderBy: orderBy,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchPaymentTypeById(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/spei_payment_types/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    savePaymentType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .post('/spei_payment_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updatePaymentType(_, { data }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put('/spei_payment_types', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchInstitutionsData(_, { id }) {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get(`/institutions/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateInstitutionsData(_, { id, payload }) {
      const formData = new FormData()
      formData.append('Id', payload.id)
      formData.append('Name', payload.name)
      formData.append('RFC', payload.rfc)
      formData.append('WebPage', payload.webPage)
      formData.append('PayeeId', 1)
      formData.append('Logo', payload.logo)
      formData.append('NetCapital', payload.netCapital)
      formData.append('MaxShortPct', payload.maxShortPct)
      formData.append('MaxLostPct', payload.maxLostPct)
      formData.append('Responsible', payload.responsible)
      formData.append('EmailResponsible', payload.emailResponsible)
      formData.append('NotificationReceiver', payload.notificationReceiver)
      formData.append('EmailNotificationReceiver', payload.emailNotificationReceiver)
      formData.append('BanxicoCode', payload.banxicoCode)
      formData.append('IsActive', payload.isActive)
      formData.append('Reference', payload.reference)
      formData.append('ResponsableMexBank', payload.responsableMexBank)
      formData.append('MinimumCapital', payload.minimumCapital)
      formData.append('BaseCapital', payload.baseCapital)
      formData.append('Version', payload.version)
      formData.append('ContractId', payload.contractId)
      formData.append('CurrencyCommission', payload.currencyCommission)
      formData.append('CostCommission', payload.costCommission)
      formData.append('TAXCommission', payload.taxCommission)
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .put(`/institutions/${id}`, formData, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
