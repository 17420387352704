import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import Vue from 'vue'
import pricesAxiosInstance from '@/utils/axios'
import sigloNetAxiosInstance from '@/utils/sigloNetAxios'

// Websockets connection
const websocketServer = `${process.env.VUE_APP_API_URL}/priceshub`

export default {
  namespaced: true,
  state: {
    prices: [],
    connections: [],
    isQuickCapture: true,
  },
  getters: {
    prices(state) {
      return state.prices
    },
    connections(state) {
      return state.connections
    },
    isQuickCapture(state) {
      return state.isQuickCapture
    },
  },
  mutations: {
    SET_PRICES(state, { clientId, prices, currencyPriceListDTO }) {
      const foundConnection = state.connections.find(
        con => con.clientId === clientId,
      )
      // If already exists we update the prices
      if (foundConnection) {
        Vue.set(foundConnection, 'prices', prices)
        Vue.set(foundConnection, 'currencyPriceListDTO', currencyPriceListDTO)
      }
    },
    ADD_OR_UPDATE_PRICES(state, { clientId, prices }) {
      const foundPrices = state.connections.find(
        con => con.clientId === clientId,
      )
      // If already exists we update the prices with a new one
      if (foundPrices) {
        Vue.set(foundPrices, 'prices', prices)
        return
      }
      // Creates a new one
      state.prices.push({ clientId, prices })
    },
    ADD_OR_UPDATE_CONNECTION(state, { clientId, connection, uniqueId }) {
      const foundConnection = state.connections.find(
        con => con.clientId === clientId,
      )
      // If already exists we update the connection with a new one
      if (foundConnection) {
        Vue.set(foundConnection, 'connection', connection)
        return
      }
      // Creates a new one
      state.connections.push({
        clientId, connection, prices: [], uniqueId: [uniqueId],
      })
    },
    REMOVE_CONNECTION(state, clientId) {
      const targetConnection = state.connections.find(con => con.clientId === clientId)
      if (targetConnection) {
        targetConnection.uniqueId.pop()
        if (!targetConnection.uniqueId.length) {
          targetConnection.connection.stop()
          state.connections.splice(
            state.connections.findIndex(con => con.clientId === clientId),
            1,
          )
        }
      }
    },
    SET_CAPTURE_TYPE(state, val) {
      state.isQuickCapture = val
    },
  },
  actions: {
    fetchPrices() {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('/get_prices_data')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPricesByCurrencyAndPortfolio(_, { currencyPairId, portfolioId }) {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('/get_prices', {
            params: {
              PortfolioId: portfolioId,
              CurrencyPairId: currencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSpotPrices(_, { currencyPairId }) {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('prices/get_spot_price_by_currencypair', {
            params: {
              CurrencyPairId: currencyPairId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchExchangePrices(
      _,
      {
        currencyPairId, intervalId, portfolioId, productBuyId, productSellId,
      },
    ) {
      return new Promise((resolve, reject) => {
        pricesAxiosInstance
          .get('/get_price', {
            params: {
              CurrencyPairId: currencyPairId,
              IntervalId: intervalId,
              PortfolioId: portfolioId,
              BuyProductId: productBuyId,
              SellProductId: productSellId,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchExchanges() {
      return new Promise((resolve, reject) => {
        sigloNetAxiosInstance
          .get('/currencies')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    startConnection(
      context,
      {
        currencyPairId, portfolioId, buyProductId, sellProductId, uniqueId, intervalId,
      },
    ) {
      let clientId
      if (intervalId) {
        clientId = `${currencyPairId}_${portfolioId}_${intervalId}_${buyProductId}_${sellProductId}`
      } else {
        clientId = `${currencyPairId}_${portfolioId}_${buyProductId}_${sellProductId}`
      }
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        const foundConnection = context.state.connections.find(
          con => con.clientId === clientId,
        )
        if (foundConnection) {
          foundConnection.uniqueId.push(uniqueId)
          return resolve()
        }
        const connection = new HubConnectionBuilder()
          .withUrl(websocketServer)
          .configureLogging(LogLevel.Information)
          .build()
        connection
          .start()
          .then(() => {
            // console.log('started')
            context.commit('ADD_OR_UPDATE_CONNECTION', { clientId, connection, uniqueId })
            connection.invoke(
              'JoinGroup',
              currencyPairId,
              portfolioId,
              buyProductId,
              sellProductId,
              portfolioId,
            ).then(() => {
              console.log('JoinGroup successful')
            }).catch(error => {
              console.error('Error invoking JoinGroup:', error)
            })
            connection.on('ReceivePrices', currencyPriceListDTO => {
              // eslint-disable-next-line
              const b = currencyPriceListDTO
              const buyPrices = b.portfolioProductPrices.filter(
                price => price.productId === buyProductId,
              )
              const sellPrices = b.portfolioProductPrices.filter(
                price => price.productId === sellProductId,
              )
              const exchanges = []
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < buyPrices?.length; i++) {
                exchanges.push({
                  intervalID: buyPrices[i].intervalID,
                  prices: [{
                    time: 'Hoy',
                    buy: buyPrices[i].buyPriceTODAY,
                    sell: sellPrices[i].sellPriceTODAY,
                  },
                  {
                    time: '24 Hrs',
                    buy: buyPrices[i].buyPrice24H,
                    sell: sellPrices[i].sellPrice24H,
                  },
                  {
                    time: '48 Hrs',
                    buy: buyPrices[i].buyPrice48H,
                    sell: sellPrices[i].sellPrice48H,
                  },
                  {
                    time: '72 Hrs',
                    buy: buyPrices[i].buyPrice72H,
                    sell: sellPrices[i].sellPrice72H,
                  },
                  {
                    time: '96 Hrs',
                    buy: buyPrices[i].buyPrice96H,
                    sell: sellPrices[i].sellPrice96H,
                  },
                  ],
                })
              }
              context.commit('SET_PRICES', { clientId, prices: exchanges, currencyPriceListDTO })
            })

            connection.onclose(async () => {
              // await start(pricePair.value.currencyPairId, pricePair.value.portfolioId, pricePair.value.productBuyId, pricePair.value.productSellId)\
              // isConnected.value = false
              // eslint-disable-next-line
              console.log('stopped', currencyPairId, portfolioId, buyProductId, sellProductId, portfolioId)
            })
            resolve()
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            // setTimeout(start, 5000)
            reject()
          })
      })
    },
  },
}
